import React from "react"

import { Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import LrButton from "../components/lrButton"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 className="text-center font-dancing text-3xl my-12">NOT FOUND</h1>
    <div className="flex items-center justify-center my-12">
      <Link to="/" className="block">
        <LrButton>
          Go Home
        </LrButton>
      </Link>
    </div>
  </Layout>
)
